<template>
  <div>
    <!-- offcanvas Repeaters -->
    <div
      id="offcanvasRepeaters"
      class="offcanvas offcanvas-end offcanvas-end--big-panel d-flex"
      :class="{ show: visible }"
      :style="{ visibility: visible ? 'visible' : 'hidden' }"
      tabindex="-1"
      aria-labelledby="offcanvasRepeatersLabel"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasRepeatersLabel"
          class="offcanvas-title"
        >
          <span class="btn btn-icon btn-icon rounded-circle btn-flat-secondary bg-light-secondary me-2"><i data-feather="send" /></span> <strong>Send reminder - {{ form.title }}</strong>
        </h3>
        <button
          type="button"
          class="btn-close text-reset btn btn-icon btn-icon rounded-circle btn-secondary"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <div class="offcanvas-body">
        <div class="row d-flex alig-items-end">
          <div class="col-sm-12">
            <label
              for=""
              class="form-label"
            >To</label>
            <v-select
              v-if="!user"
              v-model="to"
              label="name"
              :clearable="false"
              :options="[
                {name: 'All', value: 0},
                {name: 'Have answered', value: 1},
                {name: 'They have not answered', value: 2},
              ]"
              :get-option-key="option => option.value"
            />
            <div v-else>
              Sending reminder to: {{ user.name }}
            </div>
            <hr class="mt-2">
          </div>
          <div class="col-sm-12">
            <label
              for=""
              class="form-label"
            >Subject</label>
            <input
              v-model="subject"
              class="form-control"
              type="text"
            >
          </div>
          <div class="col-sm-12">
            <label
              for=""
              class="form-label"
            >Message</label>
            <textarea
              v-model="message"
              rows="20"
              class="form-control"
            />
          </div>
        </div>
      </div>
      <div class="offcanvas-footer mt-auto">
        <button
          type="button"
          class="btn btn-success mb-1 d-grid"
          @click="sendReminders"
        >
          Send
        </button>
      </div>
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    />
  </div>
  <!-- -->
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import Vue from 'vue'

export default {
  components: {
    vSelect,
  },
  props: {
    form: { type: Object, required: true },
    user: { type: Object, required: false, default: null },
  },
  data() {
    return {
      to: { name: 'All', value: 0 },
      subject: null,
      message: '',
    }
  },
  computed: {
    ...mapGetters({
      visible: 'modals/reminderForms',
    }),
  },
  async mounted() {
    feather.replace({
      width: 14,
      height: 14,
    })
  },
  methods: {
    closeOffcanva() {
      this.$store.dispatch('modals/toggleReminderForms', false)
      this.$emit('removeUser')
      this.to = { name: 'All', value: 0 }
      this.subject = null
      this.message = ''
    },
    sendReminders() {
      if (this.to && this.subject && this.message && this.form) {
        this.$store.dispatch('requestForms/sendReminder', {
          to: this.to,
          subject: this.subject,
          message: this.message,
          form: this.form.id,
          user: this.user ? this.user.id : null,
        })
        this.closeOffcanva()
      } else {
        Vue.swal('Please, fill up all fields', '', 'warning')
      }
    },
  },
}
</script>
